<template>
  <div class="tournaments">
    <div class="title">
      <p class="title-label">Upcoming Tournaments</p>
      <router-link to="/tournaments">
        <el-button class="text-black"> See All </el-button>
      </router-link>
    </div>
    <div class="py-2 md:py-0 overflow-auto">
      <div
        class="md:grid md:grid-cols-3 md:grid-rows-2 md:grid-flow-col gap-4 mt-4 mb-6 flex w-fit px-[8vw] pt-[24px] md:px-0 md:py-0"
        v-if="matchList && matchList.length"
      >
        <template
          v-for="(tournament, index) in tournamentsList"
          v-bind:key="index"
        >
          <div
            :class="`rounded-md w-[240px] md:w-[unset] bg-black ${
              (index + 1) % 3 === 0
                ? 'row-span-2 col-span-2 md:bg-slate-800'
                : 'md:bg-[unset]'
            }`"
          >
            <div class="relative" v-if="tournament">
              <router-link
                :to="tournament.thirdLink ?? tournament.url"
                :target="tournament.thirdLink ? '_blank' : '_self'"
                :aria-label="tournament.name"
              >
                <img
                  class="rounded-t-md w-full object-cover h-[135px] md:h-auto"
                  :src="tournament.image"
                  :alt="tournament.name"
                  style="
                    mask-image: linear-gradient(
                      180deg,
                      rgba(0, 0, 0, 1) 60%,
                      rgba(255, 255, 255, 0) 100%
                    );
                  "
                />
              </router-link>
              <template v-if="(index + 1) % 3 === 0">
                <div
                  id="big"
                  class="pointer-events-none flex flex-row items-center justify-start md:m-4"
                >
                  <img
                    class="w-8 h-8 hidden md:block"
                    :src="tournament.appImage"
                    alt=""
                  />
                  <div
                    class="flex flex-col md:flex-row justify-between md:items-center w-full py-1 px-3 md:py-0 md:px-1 md:ml-2"
                  >
                    <p class="pointer-events-auto text-xs font-bold text-left">
                      {{ tournament.name }}
                    </p>
                    <div class="flex flex-row gap-1 items-center">
                      <el-icon
                        class="hidden md:block"
                        color="#FFB20E"
                        size="16px"
                      >
                        <Clock />
                      </el-icon>
                      <p
                        class="pointer-events-auto text-xs font-bold text-left md:text-right"
                      >
                        {{
                          moment(tournament.happenTime).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div
                  class="pointer-events-none relative md:absolute bottom-0 flex flex-col md:flex-row justify-between md:items-center w-full py-1 px-3"
                >
                  <p class="pointer-events-auto text-xs font-bold text-left">
                    {{ tournament.name }}
                  </p>
                  <p
                    class="pointer-events-auto text-xs font-bold text-left md:text-right"
                  >
                    {{
                      moment(tournament.happenTime).format(
                        "DD/MM/YYYY HH:mm:ss"
                      )
                    }}
                  </p>
                </div>
              </template>
            </div>
            <div
              :class="`flex items-center p-3 rounded-b-md ${
                (index + 1) % 3 === 0
                  ? 'justify-between h-14 md:h-12 md:px-5'
                  : 'justify-between bg-black md:bg-slate-800 h-14'
              }`"
            >
              <p class="md:hidden text-left">£ {{ tournament.fee }}</p>
              <div
                class="flex-row items-center justify-center hidden md:flex"
                v-if="tournament.members"
              >
                <p class=""></p>
                <img
                  :class="`rounded-full ${
                    (index + 1) % 3 === 0 ? 'w-7 h-7' : 'w-6 h-6'
                  } -ml-1 border-1`"
                  :src="item"
                  alt=""
                  v-for="(item, mindex) in tournament.members.slice(0, 5)"
                  :key="mindex"
                />
                <div class="text-xs ml-1 text-left">
                  <p>
                    <span class="text-[#ffb20e]">{{
                      tournament.members !== null
                        ? tournament.members.length
                        : 0
                    }}</span>
                    / {{ tournament.mlimit }}
                  </p>
                  <p class="text-[#ffb20e]">PARTICIPANTS</p>
                </div>
              </div>
              <div v-else></div>

              <router-link
                :to="tournament.thirdLink ?? tournament.url"
                :target="tournament.thirdLink ? '_blank' : '_self'"
                class="bg-[#FFD40E] rounded-lg p-2 text-xs font-bold text-black"
                :aria-label="tournament.name"
              >
                Read more
                <span class="absolute text-clip w-0 h-0 overflow-hidden">{{
                  tournament.name
                }}</span>
              </router-link>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, toRaw } from "vue";
import { useStore } from "vuex";
import moment from "moment";
import { useRouter } from "vue-router";

export default {
  name: "upcoming-tournaments",
  setup() {
    const store = useStore();
    const matchList = computed(() => store.state.home.match);

    console.log(matchList.value);

    const tournamentsList = computed(
      () =>
        matchList.value &&
        toRaw(matchList.value).map((t) => ({
          url: `/tournaments/detail/${t.id}`,
          ...t,
        }))
    );

    onMounted(() => {
      store.dispatch("home/getHeadline");
      store.dispatch("home/getMatch");
    });

    return {
      matchList,
      moment,
      tournamentsList,
    };
  },
};
</script>

<style scoped lang="scss">
@use "@/styles/base" as *;

.tournaments {
  padding: 30px 10.94vw;

  @include media("<tablet") {
    padding: 24px 0;
  }

  .title {
    text-align: left;

    .title-label {
      flex: 1;
    }
  }

  .list {
    text-align: left;
    padding: 50px 0;
    justify-content: flex-start;

    .flex {
      display: flex;
      align-items: center;
      padding-bottom: 28px;
      justify-content: flex-start;
    }

    .name {
      font-size: 18px;
      padding: 18px;

      .flag {
        width: 16px;
        height: auto;
        display: inline-block;
      }

      img {
        width: 2.78vw;
        height: 2.78vw;
        display: block;
        margin-right: 10px;
        object-fit: cover;
        object-position: center;
      }

      p:last-child {
        font-size: 14px;
        color: #808080;
      }
    }

    .people {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 18px;
      background: #252932;

      .img-c {
        width: 50px;
        height: 50px;
        background: #bbbbbb;
        border-radius: 50%;
        padding-top: 0;
        margin-right: 10px;
      }
      .img-list {
        margin-right: 10px;
        padding-top: 0;
        position: relative;
        img {
          width: 50px;
          height: 50px;
          position: absolute;
          background: #bbbbbb;
          border-radius: 50%;
          top: 0;
          left: 0;
        }
        img:nth-child(2) {
          left: 25px;
        }
        img:nth-child(3) {
          left: 50px;
        }
        img:nth-child(4) {
          left: 75px;
        }
        img:nth-child(5) {
          left: 100px;
        }
      }

      p {
        padding-top: 8px;
        font-size: 14px;
      }

      .el-button {
        background: #ffd40e;
        border: none;
        color: Black;
        font-weight: 900;
        border-radius: 12px;
        padding: 0 20px;
        height: 48px;
      }
    }

    .people > div {
      display: flex;
      justify-content: flex-start;
      align-self: center;
    }
  }

  .list > div > div {
    border-radius: 12px;
    background: #1b1f2a;
    overflow: hidden;
  }

  .list > div:first-child {
    width: 27.2vw;
    margin-right: 14px;

    .img {
      width: 27.2vw;
      height: 174px;
      background: #bbbbbb;
      object-fit: cover;
      object-position: center;
    }
  }

  .list > div:last-child {
    width: 57.9vw;
    background: #1b1f2a;
    border-radius: 12px;
    overflow: hidden;

    .img {
      width: 57.9vw;
      height: 526px;
      background: #bbbbbb;
      object-fit: cover;
      object-position: center;
    }

    .name {
      padding: 16px 54px;
      font-size: 26px;
    }

    .people {
      padding: 20px 52px;
      border-radius: 0;
      font-size: 16px;
      .img-c {
        width: 66px;
        height: 66px;
        background: #bbbbbb;
        border-radius: 50%;
        margin-right: 10px;
        padding-top: 0;
      }
      .img-list {
        margin-right: 10px;
        padding-top: 0;
        img {
          width: 66px;
          height: 66px;
          background: #bbbbbb;
          border-radius: 50%;
          overflow: hidden;
        }
      }
    }
  }
}
</style>
