<template>
  <div class="content">
    <div>
      <img
        class="faq-log"
        :src="require('@/assets/web/logo_foot.jpg')"
        alt=""
      />
    </div>
    <div class="text-left break-words">
      For any further questions please contact us
    </div>
    <p class="text-left mt-3">General Enquiries:</p>
    <p class="text-left mb-3 font-thin">support@sidequestmeta.com</p>
    <p class="text-left mt-3">Events and Bookings:</p>
    <p class="text-left mb-3 font-thin">event@sidequestmeta.com</p>
    <p class="text-left mt-3">Franchisees:</p>
    <p class="text-left mb-3 font-thin">invest@sidequestmeta.com</p>
    <p class="text-left mt-3">Shop:</p>
    <p class="text-left mb-3 font-thin">shop@sidequestmeta.com</p>
  </div>

  <div class="flex place-content-center mt-6">
    <span class="dialog-footer">
      <el-button class="button-ok" type="primary" @click="emit('onClose')"
        >OK</el-button
      >
    </span>
  </div>
</template>

<script setup>
const emit = defineEmits(["onClose"]);
</script>

<style scoped lang="scss">
@use "@/styles/base" as *;

.content {
  width: 60vw;
  max-width: 540px;
  margin: 24px;
}

.button-ok {
  @include button-base;
  color: black;
}
</style>
